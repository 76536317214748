import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { getDashboardData } from "../services/DashboardAPIServices";

function Dashboard() {
  const spinner = document.getElementById("spinner");
  const [totalPosts, setTotalPosts] = useState("0");
  const [totalPublishedCount, setTotalPublishedCount] = useState("0");
  const [totalCategory, setTotalCategories] = useState("0");
  const [totalMonthPost, setMonthPost] = useState("0");
  const [latestPostList, setLatestPostList] = useState([]);
  const [topCategories, setTopCategories] = useState([]);

  useEffect(() => {
    spinner.style.display = "none";
    getDashboardData().then((response) => {
      setTotalPosts(response.total_posts_count);
      setTotalPublishedCount(response.published_post_count);
      setTotalCategories(response.total_categories_count);
      setMonthPost(response.this_month_posts);
      setLatestPostList(response.latest_post_list);
      setTopCategories(response.top_category_list);
    });
  }, []);

  const tagRemoveFunction = (shortContent) => {
    const tagWithContent = shortContent;
    const regex = /(<([^>]+)>)/gi;
    var shortBodyContent = tagWithContent.replace(regex, "");
    return shortBodyContent;
  };

  if (spinner) {
    //
  }
  return (
    <>
      <div className="row">
      
        <div className="col-xl-3 col-lg-6 col-md-6 col-12">
        <Link to={`/posts`}>
          <div className="card gradient-blackberry">
            <div className="card-content">
              <div className="card-body py-0">
                <div className="media pb-1">
                  <div className="media-body white text-left">
                    <h3 className="font-large-1 white mb-0">{totalPosts}</h3>
                    <span>Total Posts</span>
                  </div>
                  <div className="media-right white text-right">
                    <i className="ft-grid font-large-1"></i>
                  </div>
                </div>
              </div>
              <div
                id="Widget-line-chart"
                className="height-25 WidgetlineChart WidgetlineChartshadow mb-2"
              >
              </div>
            </div>
          </div>
          </Link>
        </div>
        
        <div className="col-xl-3 col-lg-6 col-md-6 col-12">
        <Link to={`/posts?search=${moment().format("M/Y")}`}>
          <div className="card gradient-pomegranate">
            <div className="card-content">
              <div className="card-body py-0">
                <div className="media pb-1">
                  <div className="media-body white text-left">
                    <h3 className="font-large-1 white mb-0">
                      {totalMonthPost}
                    </h3>
                    <span>{moment().format("MMMM")} Posts</span>
                  </div>
                  <div className="media-right white text-right">
                    <i className="ft-calendar font-large-1"></i>
                  </div>
                </div>
              </div>
              <div
                id="Widget-line-chart1"
                className="height-25 WidgetlineChart WidgetlineChartshadow mb-2"
              >
              </div>
            </div>
          </div>
          </Link>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-12">
        <Link to={`/posts?search=PUBLISHED`}>
          <div className="card gradient-ibiza-sunset">
            <div className="card-content">
              <div className="card-body py-0">
                <div className="media pb-1">
                  <div className="media-body white text-left">
                    <h3 className="font-large-1 white mb-0">
                      {totalPublishedCount}
                    </h3>
                    <span>Published Posts</span>
                  </div>
                  <div className="media-right white text-right">
                    <i className="ft-check-square font-large-1"></i>
                  </div>
                </div>
              </div>
              <div
                id="Widget-line-chart2"
                className="height-25 WidgetlineChart WidgetlineChartshadow mb-2"
              >
              </div>
            </div>
          </div>
          </Link>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 col-12">
          <Link to={`/categories`}>
          <div className="card gradient-flickr">
            <div className="card-content">
              <div className="card-body py-0">
                <div className="media pb-1">
                  <div className="media-body white text-left">
                    <h3 className="font-large-1 white mb-0">{totalCategory}</h3>
                    <span>Categories</span>
                  </div>
                  <div className="media-right white text-right">
                    <i className="ft-layers font-large-1"></i>
                  </div>
                </div>
              </div>
              <div
                id="Widget-line-chart3"
                className="height-25 WidgetlineChart WidgetlineChartshadow mb-2"
              >
              </div>
            </div>
          </div>
          </Link>
        </div>
        
      </div>
      <div className="row">
        <div className="col-xl-8 col-lg-12">
          <div className="card">
            <div className="card-header pb-0">
              <h4 className="card-title">Latest Posts</h4>
            </div>
            <div className="card-content">
              <div className="card-body">
                {latestPostList.map((item, i) => {
                  return (
                    <div class="card-header p-1">
                      <a
                        class="email-app-sender list-group-item list-group-item-action border-1 opened-email"
                        style={{ cursor: "default" }}
                      >
                        <div class="media">
                          <div class="media-body">
                            <h6 class="list-group-item-heading">
                              {item.title}
                            </h6>
                            <p class="list-group-item-text m-0">
                              {tagRemoveFunction(item.short_content)}...
                              <span class="float-right text muted">
                                <b>
                                  <Link to={`/post-edit/${item.id}`}>
                                    <span for="basic-form-8"> Edit </span>
                                    <i class="ft-chevrons-right"></i>
                                  </Link>
                                </b>
                              </span>
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-12 match-height">
          <div className="card">
            <div className="card-content">
              <div className="card-body">
                <h4 className="card-title">
                  <span>Top Categories</span>
                  <span className="float-right">
                    <i className="ft-more-vertical-"></i>
                  </span>
                </h4>
                <p className="grey"></p>
                <ul className="list-group mb-3">
                  {topCategories.map((item, i) => {
                    return (
                      <Link to={`/posts?search=${item.category_name}`}>
                        <li className="list-group-item">
                          <span>{item.category_name}</span>
                          <span className="badge bg-light-info float-right">
                            {item.post_count}
                          </span>
                        </li>
                      </Link>
                    );
                  })}
                </ul>
                {/* <button type="button" className="btn btn-primary mr-2">
                  Profile
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
