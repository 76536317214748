import { useParams, Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";

import { APIEndpoint, postImageUrl } from "../UrlPaths";
import Swal from "sweetalert2";

import { getActiveCategories } from "../services/CategoryAPIServices";
import {
  uplaodMainImage,
} from "../services/PostAPIServices";
import { eventActions,getSingleEventData } from "../services/EventAPIServices";

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { DatePicker, TimePicker, Modal } from "antd";
import BundledEditor from "../components/BundledEditor";
import moment from "moment";
import spacetime from "spacetime";
import TimezoneSelect, { allTimezones } from "react-timezone-select";
import dayjs from 'dayjs';

import { withNamespaces } from "react-i18next";

function EventEdit({ t }) {
  const { id } = useParams();
  const post_navigate = useNavigate();

  const inputRef = useRef();
  const spinner = document.getElementById("spinner");
  const [categoryList, setCategoryList] = useState([]);
  const [title, setTitle] = useState("");
  const [category_id, setCategoryId] = useState("");
  const [description, setPostContent] = useState("");
  const [venue, setVenue] = useState("");
  const [start_date, setStartDate] = useState("");
  const [start_time, setStartTime] = useState("");
  const [end_date, setEndDate] = useState("");
  const [end_time, setEndTime] = useState("");
  const [type, setPostType] = useState("");
  const [status, setStatus] = useState("");
  const [eventId, setEventId] = useState(id);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [time_zone, setTz] = useState("");
  const [datetime, setDatetime] = useState(spacetime.now());

  const [visible, setVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("Image Crop");
  const [featured_image, setMainImagePath] = useState("");
  const [uploadedImage, setUploadedImage] = useState("");

  useEffect(() => {
    getActiveCategories().then((response) => {
      setCategoryList(response);
      spinner.style.display = "none";
    });

    var data = { id };
    getSingleEventData(data).then((response) => {
      setTitle(response.data.title);
      setCategoryId(response.data.category_id);
      setStatus(response.data.status);
      setPostContent(response.data.description);
      setVenue(response.data.venue);
      setStartDate(response.data.start_date);
      setStartTime(response.data.start_time);
      setEndDate(response.data.end_date);
      setEndTime(response.data.end_time);
      // setTz(Intl.DateTimeFormat().resolvedOptions().response.data.time_zone)
      setDatetime(datetime.goto(response.data.time_zone));
      setEventId(id);
      setUploadedImage(`${postImageUrl}` + "/" + response.data.featured_image);
      setMainImagePath(response.data.featured_image);
    });
  }, []);

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      setPostContent(editorRef.current.getContent());
    }
  };

  const onChangeStartDate = (date, dateString) => {
    setStartDate(dateString);
  };
  const onChangeEndDate = (date, dateString) => {
    setEndDate(dateString);
  };

  const onChangeStartTime = (time, timeString) => {
    setStartTime(timeString);
  };
  const onChangeEndTime = (time, timeString) => {
    setEndTime(timeString);
  };

  //-------------------------------------------
  //save the image that used to be crop
  const [image, setImage] = useState(null);
  //change the aspect ratio of crop tool as you preferred
  const [crop, setCrop] = useState({ aspect: 16 / 9 });
  //save the resulted image
  const [result, setResult] = useState(null);

  const [srcImg, setSrcImg] = useState(null);

  const handleImage = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    // console.log(event.target.files[0]);
    // console.log(URL.createObjectURL(event.target.files[0]));
    setResult("");
    setVisible(true);
  };

  const getCroppedImg = async () => {
    if (crop.width == 0) {
      Swal.fire("Warning", t("crop_size"), "warning");
    } else {
      try {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );

        const base64Image = canvas.toDataURL("image/jpeg", 1);
        setResult(base64Image);
        console.log(result);
      } catch (e) {
        console.log("crop the image");
      }
    }
  };

  useEffect(() => {}, [srcImg]);

  const example_image_upload_handler = (blobInfo, progress) =>
    new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.open("POST", `${APIEndpoint}` + "/uplaodImageFromEditor");

      xhr.upload.onprogress = (e) => {
        progress((e.loaded / e.total) * 100);
      };

      xhr.onload = () => {
        if (xhr.status < 200 || xhr.status >= 300) {
          reject("HTTP Error: " + xhr.status);
          return;
        }

        const json = JSON.parse(xhr.responseText);

        resolve(`${postImageUrl}` + "/" + json.message);
      };

      xhr.onerror = () => {
        reject(
          "Image upload failed due to a XHR Transport error. Code: " +
            xhr.status
        );
      };

      const formData = new FormData();
      formData.append("file", blobInfo.blob(), blobInfo.filename());

      xhr.send(formData);
    });

  //-----------------------------------------------------

  const handlePostSave = (e) => {
    e.preventDefault();

      const data = {
        title,
        category_id,
        description,
        featured_image,
        venue,
        start_date,
        start_time,
        end_date,
        end_time,
        time_zone,
        eventId,
        status,
      };

      setButtonLoading(true);
      eventActions(data).then((response) => {
        if (response.status_code == 1) {
          Swal.fire("Success", "" + response.message, "success");
          setTitle("");
          setPostContent("");
          post_navigate("/events");
          post_navigate(0);
        } else {
          Swal.fire("Warning", " " + response.message, "warning");
        }
        setButtonLoading(false);
      });
 
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (result == "" || result == null) {
      Swal.fire("Warning", t("crop_image"), "warning");
    } else {
      const data = new FormData();
      data.append("file", result);
      uplaodMainImage(data).then((response) => {
        if (response.status_code == 1) {
          Swal.fire("Success", t("image_upload_success"), "success");
          setMainImagePath(response.message);
          setUploadedImage(`${postImageUrl}` + "/" + response.message);
          setVisible(false);
        } else {
          Swal.fire("Warning", " " + response.message, "warning");
        }
      });
    }
  };

  const popupDocument = (url) => {
    let win = window.open(
      url,
      null,
      "popup,width=500,height=600,left=0,right=0"
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-8">
          <h3> Edit Event </h3>
        </div>
      </div>
      <section id="action-form-layout">
        <div className="row match-height">
          <div className="col-8">
            <div className="card">
              <div className="card-header">
                <div className="form-group float-left">
                  <b>
                    <Link to={`/events`}>
                      <i class="ft-chevrons-left"></i>
                      <span for="basic-form-8"> All Events </span>
                    </Link>
                  </b>
                </div>

                {/* <h4 className="card-title">Form Actions</h4> */}
              </div>
              <div className="card-content">
                <div className="card-body">
                  <div className="form-row">
                    <div className="col-md-12 col-12">
                      <div className="form-group position-relative">
                        <label for="title">
                          Title <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          id="title"
                          className="form-control"
                          placeholder="Title"
                          name="title"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 col-12">
                      <div className="form-group position-relative">
                        <label for="venue">
                          venue <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          id="venue"
                          className="form-control"
                          placeholder="Venue"
                          name="venue"
                          value={venue}
                          onChange={(e) => setVenue(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-6 col-12">
                      <div className="form-group position-relative">
                        <label for="form-action-3">
                          Category <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          id="category_id"
                          name="category_id"
                          className="form-control"
                          value={category_id}
                          onChange={(e) => setCategoryId(e.target.value)}
                          required
                        >
                          <option value="" disabled>
                            select category
                          </option>
                          {categoryList.map((item, i) => {
                            return (
                              <>
                                {item.status == 1 ? (
                                  <option value={item.id} key={i}>
                                    {item.category_name}
                                  </option>
                                ) : (
                                  <></>
                                )}
                              </>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 col-12"></div>
                  </div>
                  <div className="form-group">
                    <label for="form-action-7">
                      Description <span style={{ color: "red" }}>*</span>
                    </label>
                    <BundledEditor
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      onChange={log}
                      initialValue={description}
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          "advlist",
                          "anchor",
                          "autolink",
                          "image",
                          "link",
                          "lists",
                          "searchreplace",
                          "table",
                          "wordcount",
                        ],
                        toolbar:
                          "blocks | " +
                          "bold italic forecolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist | image | table" +
                          "removeformat |  outdent indent",
                        toolbar_sticky: true,
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        a11y_advanced_options: true,
                        image_advtab: true,
                        image_uploadtab: true,
                        automatic_uploads: true,
                        relative_urls: false,
                        remove_script_host: false,
                        images_upload_handler: example_image_upload_handler,
                      }}
                    />
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            {/* <div className="card"> */}
            <div className="row">
            <div className="col-12 col-md-11">
              <form
                onSubmit={(e) => {
                  handlePostSave(e);
                }}
              >
                <button
                  type="submit"
                  className="btn btn-warning float-right"
                  disabled={buttonLoading}
                  style={{float:'right'}}
                >
                  Update
                </button>
              </form>
              </div>
            </div>
            <br></br>
            <div className="row">
              <div className="col-12 col-md-7">
                <div className="form-group">
                  <label for="basic-form-8">Status</label>
                  <select
                    id="status"
                    name="status"
                    className="form-control"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    required
                  >
                    <option value="1"> Active</option>
                    <option value="0"> Inactive</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-12">
                <div className="form-group">
                  <label for="basic-form-8">
                    starts <span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <DatePicker selected={start_date} value={moment(start_date)} onChange={onChangeStartDate} />
                  <TimePicker
                    use24Hours
                    format="HH:mm"
                    selected={start_time}
                    defaultValue={start_time}
                    // value={start_time}
                    onChange={onChangeStartTime}
                  />
                </div>
              </div>
              <div className="col-12 col-md-12">
                <div className="form-group">
                  <label for="basic-form-8">
                    Ends <span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <DatePicker selected={end_date} value={moment(end_date)} onChange={onChangeEndDate} />
                  <TimePicker
                    use24Hours
                    format="HH:mm"
                    onChange={onChangeEndTime}
                  />
                </div>
              </div>
              <div className="col-12 col-md-12">
                <div className="form-group">
                  <label for="basic-form-8">
                    TimeZone <span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <TimezoneSelect
                    value={time_zone}
                    onChange={setTz}
                    labelStyle="altName"
                    timezones={{
                      ...allTimezones,
                      "America/Lima": "Pittsburgh",
                      "Europe/Berlin": "Frankfurt",
                    }}
                  />
                </div>
              </div>
              <div className="col-12 col-md-12">
                <div className="form-group">
                  <label for="basic-form-8">
                    Featured Image <span style={{ color: "red" }}>*</span>
                  </label>
                  {/* <ImageResize /> */}
                  {/* <DropZone/> */}
                  <input
                    type="file"
                    name="document"
                    accept="image/*"
                    className="form-control"
                    // ref={inputRef}
                    required
                    onChange={handleImage}
                    // onChange={(e) => setDocument(inputRef.current.files[0])}
                  />
                  <p className="text-muted mb-0 mt-1 mt-sm-0">
                    <small>*Allowed JPG, PNG or JPEG.</small>
                  </p>
                </div>
              </div>

              <div className="col-12 col-md-12">
                {uploadedImage && (
                  <>
                    <img
                      src={uploadedImage}
                      className="img-fluid"
                      alt="cropped image"
                    />

                    <button
                      type="button"
                      id="popupBtnId"
                      class="btn btn-info bg-light-info"
                      onClick={() => popupDocument(`${uploadedImage}`)}
                    >
                      <i className="ft-eye"></i>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        title={modalTitle}
        maskClosable={false}
        visible={visible}
        okText="Upload"
        onCancel={() => setVisible(false) /*ResetEditing() */}
        onOk={(e) => /*setVisible(false)*/ {
          handleSubmit(e);
        }}
      >
        <div>
          {srcImg && (
            <div>
              <ReactCrop
                style={{ maxWidth: "90%" }}
                src={srcImg}
                onImageLoaded={setImage}
                crop={crop}
                onChange={setCrop}
              />
              <button
                className="btn btn-warning cropButton"
                onClick={getCroppedImg}
              >
                Crop
              </button>
            </div>
          )}
          {result && (
            <div>
              <label>
                <b>{t("cropped_image_header")}</b>
              </label>
              <br />
              <img src={result} alt="cropped image" />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}
export default withNamespaces()(EventEdit);
