import axios from "axios";
import { APIEndpoint } from "../UrlPaths";
const token = sessionStorage.getItem("access_token");

export async function getCategories() {
  try {
    const response = await axios.get(APIEndpoint + "/getAllCategories", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function getActiveCategories() {
  try {
    const response = await axios.get(APIEndpoint + "/getActiveCategories", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function categoryActions(data) {
  try {
    const response = await axios.post(APIEndpoint + "/storeCategories", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function categoryStatusChange(data) {
  try {
    const response = await axios.post(APIEndpoint + "/categoryStatusChange", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return error;
  }
}


