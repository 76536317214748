import React, { useState, useEffect } from "react";
import { getAllPosts, postStatusChange } from "../services/PostAPIServices";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Table, Input, Button, Switch } from "antd";
import "antd/dist/antd.css";
import {
  SearchOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { padding } from "@mui/system";

function Post() {
  const queryParameters = new URLSearchParams(window.location.search);
  const search = queryParameters.get("search");

  const spinner = document.getElementById("spinner");
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchedText, setSearchedText] = useState("");

  const columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      sortDirections: ["descend"],
      hidden: true,
    },
    {
      key: "title",
      title: "Title",
      filteredValue: [searchedText],
      render: (record) => {
        return (
          <>
            <div className="flex">
              <Link to={`/post-view/${record.id}`}>
                <span>{record.title}</span>
              </Link>
              <br></br>
              <small className="text-muted">{record.status}</small>
            </div>
          </>
        );
      },
      onFilter: (value, record) => {
        return (
          record.title.toLowerCase().includes(value.toLowerCase()) ||
          record.author.toLowerCase().includes(value.toLowerCase()) ||
          record.category_name.toLowerCase().includes(value.toLowerCase()) ||
          record.status.toLowerCase().includes(value.toLowerCase()) ||
          record.updated_date.toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      key: "author",
      title: "Author",
      dataIndex: "author",
    },
    {
      key: "category_name",
      title: "Category",
      dataIndex: "category_name",
    },
    {
      key: "status",
      title: "Published",
      render: (record) => {
        return (
          <>
            {" "}
            <div className="flex">
              {record.status == "PUBLISHED" ? (
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultChecked
                  onClick={() => handleClick("DRAFT", record.id)}
                />
              ) : (
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultUnChecked
                  onClick={() => handleClick("PUBLISHED", record.id)}
                />
              )}
            </div>
          </>
        );
      },
    },
    {
      key: "updated_date",
      title: "Last Modified",
      dataIndex: "updated_date",
    },
    {
      key: "action",
      title: "Actions",
      render: (record) => {
        return (
          <>
            <div className="flex">
              <Link to={`/post-edit/${record.id}`}>
                <button className="badge bg-light-danger">
                  <i class="ft-edit"></i>
                </button>
              </Link>
            </div>
          </>
        );
      },
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    console.log(search);
    if (search != "" && search != null) {
      setSearchedText(search);
    }
    setLoading(true);

    getAllPosts().then((response) => {
      //  console.log(response);
      spinner.style.display = "none";
      setTableData(response);
      setLoading(false);
    });
  }, []);

  const handleClick = (status, postId) => {
    const data = { status, postId };

    postStatusChange(data).then((response) => {
      if (response.status_code == 1) {
        Swal.fire("Success", "" + response.message, "success");
      } else {
        Swal.fire("Warning", " " + response.message, "warning");
      }

      setLoading(true);

      getAllPosts().then((response) => {
        setTableData(response);
        setLoading(false);
      });
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-8">
          <h3>All Posts</h3>
          <p style={{ color: "grey" }}>
            Create, edit, and manage the posts on site.
          </p>
        </div>
        <div className="col-4">
          <Link to="/new-post">
            <button
              type="button"
              class="btn btn-primary mb-3 d-block  float-right"
            >
              Add new post
            </button>
          </Link>
        </div>
      </div>

      <section className="users-list-wrapper">
        <div className="users-list-table">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    <div className="col-xl-6"></div>
                    <div className="col-xl-6">
                      <Input.Search
                        placeholder="Search here..."
                        style={{ margin: 10 }}
                        value={searchedText}
                        onSearch={(value) => {
                          setSearchedText(value);
                        }}
                        onChange={(e) => {
                          setSearchedText(e.target.value);
                        }}
                      />
                    </div>
                    <div className="table-responsive">
                      <Table
                        loading={loading}
                        dataSource={tableData}
                        columns={columns}
                        pagination={{
                          pageSize: 25,
                          showSizeChanger: true,
                          position: ["bottomCenter"],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Post;
