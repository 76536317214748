import axios from "axios";
import { APIEndpoint } from "../UrlPaths";
const token = sessionStorage.getItem("access_token");

export async function getAllVideos() {
  try {
    const response = await axios.get(APIEndpoint + "/getAllVideos", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function storeVideo(data) {
  try {
    const response = await axios.post(APIEndpoint + "/storeVideo", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

