import axios from "axios";
import { APIEndpoint } from '../UrlPaths';

const token = sessionStorage.getItem("access_token");

export async function getAllPosts() {
  try {
    const response = await axios.get(
      APIEndpoint+'/getAllPosts',
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getAllPostTypes() {
  try {
    const response = await axios.get(
      APIEndpoint+'/getAllPostTypes',
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}


export async function storePosts(data) {
  try {
    const response = await axios.post(
      `${APIEndpoint}/storePost`, data,
      { headers: { 'Authorization': `Bearer ${token}`} });

    return response.data;
  } catch (error) {
   
    return error;
  }
}

export async function getPostRevisions(data) {
  try {
    const response = await axios.post(
      `${APIEndpoint}/getPostRevisions`, data,
      { headers: { 'Authorization': `Bearer ${token}`} });

    return response.data;
  } catch (error) {
   
    return error;
  }
}

export async function getSinglePostData(data) {
  try {
    const response = await axios.post(
      `${APIEndpoint}/getSinglePostData`, data,
      { headers: { 'Authorization': `Bearer ${token}`} });

    return response.data;
  } catch (error) {
   
    return error;
  }
}

  export async function postStatusChange(data) {
    try {
      const response = await axios.post(
        `${APIEndpoint}/postStatusChange`, data,
        { headers: { 'Authorization': `Bearer ${token}`} });
  
      return response.data;
    } catch (error) {
     
      return error;
    }
}

export async function uplaodMainImage(data) {
  try {
    const response = await axios.post(
      `${APIEndpoint}/uplaodMainImage`, data,
      { headers: { 'Authorization': `Bearer ${token}`} });

    return response.data;
  } catch (error) {
   
    return error;
  }
}

export async function revisionRestore(data) {
  try {
    const response = await axios.post(
      `${APIEndpoint}/restorePost`, data,
      { headers: { 'Authorization': `Bearer ${token}`} });

    return response.data;
  } catch (error) {
   
    return error;
  }
}


export async function supportedLanguages() {
  try {
    const response = await axios.get(APIEndpoint + "/supportedLanguages", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function storePostPreview(data) {
  try {
    const response = await axios.post(
      `${APIEndpoint}/storePostPreview`, data,
      { headers: { 'Authorization': `Bearer ${token}`} });

    return response.data;
  } catch (error) {
   
    return error;
  }
}