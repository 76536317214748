import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getAllEvents } from "../services/EventAPIServices";
import { Table, Input, Button } from "antd";
import "antd/dist/antd.css";
import { SearchOutlined } from "@ant-design/icons";

function Event() {
  const spinner = document.getElementById("spinner");
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      sortDirections: ["descend"],
      hidden: true,
    },
    {
      key: "title",
      title: "Title",
      dataIndex: "title",
      filterMode: "tree",
      filterSearch: true,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type title"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropDown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.title.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "venue",
      title: "Venue",
      dataIndex: "venue",
    },
    {
      key: "start_date",
      title: "Start Date",
      dataIndex: "start_date",
    },
    {
      key: "start_time",
      title: "Start Time",
      dataIndex: "start_time",
    },
    {
      key: "time_zone",
      title: "Time zone",
      dataIndex: "time_zone",
    },
    {
      key: "status",
      title: "Status",
      render: (record) => {
        return (
          <>
            {" "}
            <div className="flex">
              {record.status == "1" ? (
                <span className="badge bg-light-success">Active</span>
              ) : (
                <span className="badge bg-light-danger">Inactive</span>
              )}
            </div>
          </>
        );
      },
    },
    {
      key: "action",
      title: "Actions",
      render: (record) => {
        return (
          <>
            <div className="flex">
            <Link to={`/event-edit/${record.id}`}>
                <button className="badge bg-light-danger">
                  <i class="ft-edit"></i>
                </button>
              </Link>
            </div>
          </>
        );
      },
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    setLoading(true);

    getAllEvents().then((response) => {
      //  console.log(response);
      spinner.style.display = "none";
      setTableData(response);
      setLoading(false);
    });
  }, []);

  const Edit = (data) => {};

  return (
    <>
      <div className="row">
        <div className="col-8">
          <h3> Events</h3>
          <p style={{ color: "grey" }}>
            Create, edit, and manage the events on site.
          </p>
        </div>
        <div className="col-4">
          <Link to="/new-event">
            <button
              type="button"
              class="btn btn-primary mb-3 d-block  float-right"
            >
              Add New Event
            </button>
          </Link>
        </div>
      </div>

      <section className="users-list-wrapper">
        <div className="users-list-table">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    <div className="table-responsive">
                      <Table
                        loading={loading}
                        dataSource={tableData}
                        columns={columns}
                        pagination={{
                          pageSize: 10,
                          showSizeChanger: true,
                          position: ["bottomCenter"],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Event;
