import React, { useState, useEffect, useRef } from "react";
// import DatePicker  from 'react-datepicker';
import { Link, useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import moment from "moment";
// import 'react-datepicker/dist/react-datepicker.css';
import { APIEndpoint, postImageUrl } from "../UrlPaths";
import Swal from "sweetalert2";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { getActiveCategories } from "../services/CategoryAPIServices";
import {
  storePosts,
  getAllPostTypes,
  uplaodMainImage,
  supportedLanguages,
  storePostPreview
} from "../services/PostAPIServices";
import BundledEditor from "../components/BundledEditor";
// import DropZone from '../components/DropZone';
import ImageResize from "../components/ImageResize";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Select, Modal } from "antd";

import { withNamespaces } from "react-i18next";

function NewPost({ t }) {

  //  moment.tz.setDefault("Asia/Colombo");
  const inputRef = useRef();
  const post_nav = useNavigate();
  const { Option } = Select;
  const spinner = document.getElementById("spinner");
  const [categoryList, setCategoryList] = useState([]);
  const [postTypeList, setTypeList] = useState([]);
  const [languagesList, setLanguagesList] = useState([]);
  const [language, setLanguage] = useState("1");
  const [title, setTitle] = useState("");
  const [category_id, setCategoryId] = useState("");
  const [post_content, setPostContent] = useState("");
  const [type, setPostType] = useState("");
  const [postId, setPostId] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState("");
  const [modalTitle, setModalTitle] = useState("Image Crop");
  const [visible, setVisible] = useState(false);
  const [main_image_path, setMainImagePath] = useState("");
  const [publishDate, setPublishDate] = useState(moment());

  useEffect(() => {
    getActiveCategories().then((response) => {
      setCategoryList(response);
      spinner.style.display = "none";
    });
    getAllPostTypes().then((res) => {
      setTypeList(res);
    });
    supportedLanguages().then((res) => {
      setLanguagesList(res.languages);
    });
  }, []);

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      setPostContent(editorRef.current.getContent());
      console.log(editorRef.current.getContent());
    }
  };

  //-------------------------------------------
  //save the image that used to be crop
  const [image, setImage] = useState(null);
  //change the aspect ratio of crop tool as you preferred
  const [crop, setCrop] = useState({ aspect: 16 / 9 });
  //save the resulted image
  const [result, setResult] = useState(null);
  const [srcImg, setSrcImg] = useState(null);

  const handleImage = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    console.log(event.target.files[0]);
    console.log(URL.createObjectURL(event.target.files[0]));
    setVisible(true);
  };

  const getCroppedImg = async () => {

    if( crop.width == 0){
      Swal.fire("Warning", t("crop_size"), "warning");
    }else{

    try {
      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      const base64Image = canvas.toDataURL("image/jpeg", 1);
      setResult(base64Image);
      // console.log(result);
    } catch (e) {
      console.log("crop the image");
    }
  }
  };

  useEffect(() => {
    // console.log(replyDataList);
  }, [srcImg]);


  const onChangeStartDate = (date, dateString) => {
    setPublishDate(dateString);
   
  };

  //------------------------------------------

  const handlePostPublish = (e) => {
    e.preventDefault();
    var status = "PUBLISHED";
   // console.log(main_image_path);
    const data = {
      title,
      category_id,
      type,
      post_content,
      main_image_path,
      language,
      postId,
      publishDate,
      status,
    };    

    setButtonLoading(true);
    storePosts(data).then((response) => {
      if (response.status_code == 1) {
        Swal.fire("Success", "" + response.message, "success");
        setTitle("");
        setPostContent("");
        post_nav("/posts");
        post_nav(0);
      } else {
        Swal.fire("Warning", " " + response.message, "warning");
      }
      setButtonLoading(false);
    });
  };

  const handlePostDraft = (e) => {
    e.preventDefault();
    var status = "DRAFT";
    const data = {
      title,
      category_id,
      type,
      post_content,
      main_image_path,
      language,
      postId,
      publishDate,
      status,
    };
    document.getElementById("publishBtn").disabled = true;
    // setButtonLoading(true);
    storePosts(data).then((response) => {
      if (response.status_code == 1) {
        Swal.fire("Success", "" + response.message, "success");
        setTitle("");
        setPostContent("");
        post_nav("/posts");
        post_nav(0);
      } else {
        Swal.fire("Warning", " " + response.message, "warning");
        
      }
      // setButtonLoading(false);
      document.getElementById("publishBtn").disabled = false;
    });
  };

  const handlePostPreview = (e) => {
    e.preventDefault();
    var status = "PREVIEW";
    const data = {
      title,
      category_id,
      post_content,
      main_image_path,
      language,
      postId,
      status,
    };

    storePostPreview(data).then((response) => {
      console.log(response);
      if (response.status_code == 1) {
        window.open(response.data.link, null,
          "popup,width=1200,height=900,left=0,right=0");
      } else {
        Swal.fire("Warning", " " + response.message, "warning");
        
      }
    });
  };


  const onChangePostTypes = (value) => {
    setPostType(value);
  };

  //---------------------------------------------------------------------

  const example_image_upload_handler = (blobInfo, progress) =>
    new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.open("POST", `${APIEndpoint}` + "/uplaodImageFromEditor");

      xhr.upload.onprogress = (e) => {
        progress((e.loaded / e.total) * 100);
      };

      xhr.onload = () => {
        if (xhr.status < 200 || xhr.status >= 300) {
          reject("HTTP Error: " + xhr.status);
          return;
        }

        const json = JSON.parse(xhr.responseText);
        console.log(json);
        // if (!json || typeof json.location != 'string') {
        //   reject('Invalid JSON: ' + xhr.responseText);
        //   return;
        // }

        resolve(`${postImageUrl}` + "/" + json.message);
      };

      xhr.onerror = () => {
        reject(
          "Image upload failed due to a XHR Transport error. Code: " +
            xhr.status
        );
      };

      const formData = new FormData();
      formData.append("file", blobInfo.blob(), blobInfo.filename());

      xhr.send(formData);
    });

  //-----------------------------------------------------------------------------------
  const handleSubmit = (e) => {
    e.preventDefault();
    if (result == "" || result == null) {
      Swal.fire("Warning", t("crop_image"), "warning");
    } else {
    const data = new FormData();
    data.append("file", result);

    uplaodMainImage(data).then((response) => {
      if (response.status_code == 1) {
        Swal.fire("Success", t("image_upload_success"), "success");
        setMainImagePath(response.message);
        setUploadedImage(`${postImageUrl}` + "/" + response.message);
        setVisible(false);
      } else {
        Swal.fire("Warning", " " + response.message, "warning");
      }
    });
  }
  };

  
  const popupDocument = (url) => {
    let win = window.open(
      url,
      null,
      "popup,width=500,height=600,left=0,right=0"
    );
  };


  return (
    <>
      <div className="row">
        <div className="col-8">
          <h3> New Post </h3>
        </div>
      </div>
      <section id="action-form-layout">
        <div className="row match-height">
          <div className="col-9">
          
            <div className="card">
              
              <div className="card-header">
                <b>
                  <Link to={`/posts`} style={{float:'left'}}>
                    <i class="ft-chevrons-left"></i>
                    <span for="basic-form-8"> All Posts </span>
                  </Link>
                </b>

                <form
                onSubmit={(e) => {
                  handlePostPreview(e);
                }}
              >
                <button
                  type="submit"
                  className="btn bg-light-secondary float-right"
                >
                  <span for="basic-form-8"> Preview </span>
                  <i class="fa fa-external-link"></i>
                </button>
              </form>
              
               
              </div>
              <div className="card-content">
                <div className="card-body">
                  <div className="form-row">
                    <div className="col-md-12 col-12">
                      <div className="form-group position-relative">
                        <label for="title">
                          Title <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          id="title"
                          className="form-control"
                          placeholder="Title"
                          name="title"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-6 col-12">
                      <div className="form-group position-relative">
                        <label for="form-action-3">
                          Category <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          id="category_id"
                          name="category_id"
                          className="form-control"
                          value={category_id}
                          onChange={(e) => setCategoryId(e.target.value)}
                          required
                        >
                          <option value="" disabled>
                            select category
                          </option>
                          {categoryList.map((item, i) => {
                            return (
                              <>
                                {item.status == 1 ? (
                                  <option value={item.id} key={i}>
                                    {item.category_name}
                                  </option>
                                ) : (
                                  <></>
                                )}
                              </>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="form-group position-relative">
                        <label for="form-action-3">
                          Post type <span style={{ color: "red" }}>*</span>
                        </label>

                        <Select
                          mode="multiple"
                          allowClear
                          style={{
                            width: "100%",
                          }}
                          placeholder="Please select"
                          onChange={onChangePostTypes}
                          filterOption={(input, option) =>
                            option.children
                              .toString()
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          required
                        >
                          {postTypeList.map((item, i) => {
                            return (
                              <Option value={item.id} key={i}>
                                {item.type}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label for="form-action-7">
                      Post Content <span style={{ color: "red" }}>*</span>
                    </label>

                    <BundledEditor
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      onChange={log}
                      initialValue={post_content}
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          "advlist",
                          "anchor",
                          "autolink",
                          "image",
                          "link",
                          "lists",
                          "searchreplace",
                          "table",
                          "media",
                          "mediaembed",
                          "wordcount",
                        ],
                        toolbar:
                          "blocks | " +
                          "bold italic forecolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist image | " +
                          "link table media | outdent indent",
                        toolbar_sticky: true,
                        content_style:
                          "body { font-family:Roboto, sans-serif; font-size:16px }",
                        a11y_advanced_options: true,
                        image_advtab: true,
                        image_uploadtab: true,
                        automatic_uploads: true,
                        relative_urls: false,
                        remove_script_host: false,
                        images_upload_handler: example_image_upload_handler,
                        // video_template_callback: function(data) {
                        //   console.log(data.source);
                        //   if (data.source.includes('assets/videos/')) {
                        //     var embedHtml = '<video controls="controls" controlsList="nodownload" width="'+data.width+'" height="' + data.height + '"> <source src="https://admin.ln.archnix.dev/' + data.source + '" type="'+data.sourcemime+'"></video>';
                        //     return embedHtml;
                        //   } else {
                        //     return "";
                        //   }
                        // }
                      }}
                    />
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            {/* <div className="card"> */}
            {/* <div className="row"> */}
            <div className="form-group">
              {/* <label for="basic-form-8">Status & Visibility</label> */}
              <form
                onSubmit={(e) => {
                  handlePostDraft(e);
                }}
              >
                <button
                  type="submit"
                  style={{
                    border: "none",
                    background: "none",
                    padding: 2,
                    decoration: "underline",
                    color: "blue",
                  }}
                  className="float-left"
                  disabled={buttonLoading}
                >
                  Save to draft
                </button>
              </form>
              <form
                onSubmit={(e) => {
                  handlePostPublish(e);
                }}
              >
                <button
                  type="submit"
                  id="publishBtn"
                  className="btn btn-primary float-right"
                  disabled={buttonLoading}
                >
                  {buttonLoading && (
                    <>
                      <i className="ft-refresh-ccw"> </i>
                      <span>Publishing...</span>
                    </>
                  )}
                  {!buttonLoading && (
                    <>
                      <i className="ft-check-square mr-1"></i>
                      <span> Publish</span>{" "}
                    </>
                  )}
                </button>
              </form>
              {/* </div> */}
            </div>
            <br></br> <br></br> <br></br>
            <div className="row">
            <div className="col-12 col-md-12">
                <div className="form-group">
                  <label for="basic-form-8">
                   Publish Date <span style={{ color: "red" }}>*</span>
                  </label>
                  <DatePicker 
                    onChange={onChangeStartDate}
                    format={'DD/MM/YYYY HH:mm'}
                    disabledDate={(current) => current.valueOf() > Date.now()}
                    defaultValue={publishDate !== ""
                    ? moment(publishDate)
                    : moment()}
                    />
                </div>
              </div>
            <div className="col-12 col-md-12">
                <div className="form-group">
                  <label for="basic-form-8">
                    Post's Language <span style={{ color: "red" }}>*</span>
                  </label>
                    <select
                          id="language"
                          name="language"
                          className="form-control"
                          value={language}
                          onChange={(e) => setLanguage(e.target.value)}
                          required
                        >
                          {languagesList.map((item, i) => {
                            return (
                              <>
                                {item.status == 1 ? (
                                  <option value={item.id} key={i}>
                                    {item.language_in_english}
                                  </option>
                                ) : (
                                  <></>
                                )}
                              </>
                            );
                          })}
                    </select>
                </div>
              </div>
              <div className="col-12 col-md-12">
                <div className="form-group">
                  <label for="basic-form-8">
                    Main Image <span style={{ color: "red" }}>*</span>
                  </label>
                  {/* <ImageResize /> */}
                  {/* <DropZone/> */}
                  <input
                    type="file"
                    name="document"
                    accept="image/*"
                    className="form-control"
                    // ref={inputRef}
                    required
                    onChange={handleImage}
                    // onChange={(e) => setDocument(inputRef.current.files[0])}
                  />
                  <p class="text-muted mb-0 mt-1 mt-sm-0">
                    <small>*Allowed JPG, PNG or JPEG.</small>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-12">
                {uploadedImage && (<>
                  <img
                    src={uploadedImage}
                    className="img-fluid"
                    alt="cropped image"
                  />
                  
                  <button
                  type="button"
                  id="popupBtnId"
                  class="btn btn-info bg-light-info"
                  onClick={() => popupDocument(`${uploadedImage}`)}
                >
                  <i className="ft-eye"></i>
                </button>
                </>)}
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </section>
      <Modal
        title={modalTitle}
        maskClosable={false}
        visible={visible}
        okText="Upload"
        onCancel={() => setVisible(false) /*ResetEditing() */}
        onOk={(e) => /*setVisible(false)*/ {
          handleSubmit(e);
        }}
      >
        <div>
          {srcImg && (
            <div>
              <ReactCrop
                style={{ maxWidth: "90%" }}
                src={srcImg}
                onImageLoaded={setImage}
                crop={crop}
                onChange={setCrop}
              />
              <button
                className="btn btn-warning cropButton"
                onClick={getCroppedImg}
              >
                Crop
              </button>
            </div>
          )}
          {result && (
            <div>
              <label><b>{t("cropped_image_header")}</b></label><br/>
              <img src={result} alt="cropped image" />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default withNamespaces()(NewPost);
