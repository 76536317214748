import axios from "axios";
import { APIEndpoint } from "../UrlPaths";
const token = sessionStorage.getItem("access_token");

export async function getAllEpapers() {
  try {
    const response = await axios.get(APIEndpoint + "/getAllEpapers", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function ePaperActions(data) {
  try {
    const response = await axios.post(APIEndpoint + "/storeEpaper", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return error;
  }
}
