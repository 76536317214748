import axios from "axios";
import { APIEndpoint } from "../UrlPaths";
const token = sessionStorage.getItem("access_token");

export async function getAllEvents() {
  try {
    const response = await axios.get(APIEndpoint + "/getAllEvents", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function eventActions(data) {
  try {
    const response = await axios.post(APIEndpoint + "/storeEvents", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function getSingleEventData(data) {
  try {
    const response = await axios.post(
      `${APIEndpoint}/getSingleEventData`, data,
      { headers: { 'Authorization': `Bearer ${token}`} });

    return response.data;
  } catch (error) {
   
    return error;
  }
}

